.notifications {
  position: absolute;
  /* top: 31%; */
  /* right: -5%; */
  /* left: 83%; */
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #f35d5d !important;
  color: #ffffff;
  /* font-size: 16px; */
  /* padding: 12px 24px; */
  border: none;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  width: auto;
  height: auto;
}

.addNotifications {
  position: absolute;
  top: -5%; 
right: -20px; 
  /* left: 83%; */
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #f35d5d !important;
  color: #ffffff;
  /* font-size: 16px; */
  /* padding: 12px 24px; */
  border: none;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  width: auto;
  height: auto;
}
.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 5px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
  margin-left: -10px;
  
}