.divisionstyle {
  position: absolute;
  top: 74%;
  right: -9%;

  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #f35d5d !important;

  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  width: 60px;
  height: 23px;
}

.addbtnplus {
  position: absolute;
  top: 48%;
  right: -18%;

  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #f35d5d !important;
  color: #ffffff;

  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  width: max-content;
  height: 23px;
}
.minusbtn {
  position: absolute;
  top: 48%;
  right: 50%;

  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #f35d5d !important;
  color: #ffffff;

  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  width: 20px;
  height: 23px;
}
.addingbtn {
  position: absolute;
  top: 51%;

  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: white !important;
  color: black;

  border: none;
  cursor: pointer;
  border-radius: 0px;
  text-align: center;
  width: max-content;
  height: 24px;
}

.row {
  position: absolute;

  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: green !important;
  top: 54%;
  right: -25%;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  width: 60px;
  height: 23px;
}
.rowImage {
  padding-top: 8px !important;
}
.col-xs-4 {
  position: absolute;

  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: blue !important;
  top: 54%;
  right: -48.5%;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  width: 60px;
  height: 23px;
}
@media (min-width: 320px) and (max-width: 359px) {
  .divisionstyleTrue {
    position: absolute;
    top: 82%;
    right: 105.1%;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }

  .addingCustBtnStyles {
    color: #fff !important;
    padding-top: 2.6px !important;
    padding-left: 2px !important;
    font-weight: 600 !important;
  }
  .imagePo {
    padding-right: 17px !important;
    margin-left: -25px !important;
    height: 68px !important;
  }
  .productList {
    /*  max-height: 16.8rem !important; */
    overflow-y: scroll !important;
  }
  .bootomButton {
    padding-top: 0px !important;
  }
  .po {
    background-color: #f3f7f8;
    height: 100%;
  }
  .divisionstyle {
    position: absolute;
    top: 95%;
    right: -19%;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }

  /* .addbtnTrue {
    position: absolute;
    top: 82%;
    right: 108%;

    width: 55px !important;
    height: 25px !important;
    display: flex;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;
    color: #ffffff;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: max-content;
  } */
}

@media (min-width: 360px) and (max-width: 374px) {
  .card {
    width: 80px;
    height: 110px;
  }
  .imagePo {
    padding-right: 16px !important;
    margin-left: -16px !important;
    height: 68px !important;
  }

  .addingCustBtnStyles {
    color: #fff !important;
    padding-top: 2.6px !important;
    padding-left: 2px !important;
    font-weight: 600 !important;
  }
  .divisionstyle {
    position: absolute;
    top: 95%;
    right: -15.8%;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }
  .divisionstyleTrue {
    position: absolute;
    top: 82%;
    right: 91.1%;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }

  .addbtnplus {
    position: absolute;
    top: 48%;
    right: -18%;
    /* left: 83%; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;
    color: #ffffff;
    /* font-size: 16px; */
    /* padding: 12px 24px; */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: max-content;
    height: 23px;
  }
  .minusbtn {
    position: absolute;
    top: 48%;
    right: 50%;
    /* left: 83%; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;
    color: #ffffff;
    /* font-size: 16px; */
    /* padding: 12px 24px; */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 20px;
    height: 23px;
  }
  .addingbtn {
    position: absolute;
    top: 51%;

    /* left: 83%; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: white !important;
    color: black;
    /* font-size: 16px; */
    /* padding: 12px 24px; */
    border: none;
    cursor: pointer;
    border-radius: 0px;
    text-align: center;
    width: max-content;
    height: 24px;
  }
  .productList {
    /* max-height: 21.2rem !important; */
    overflow-y: scroll !important;
  }
  .bootomButton {
    padding-top: 0px !important;
  }
  .po {
    background-color: #f3f7f8;
    height: 100%;
  }
}

@media (min-width: 375px) and (max-width: 667px) {
  .divisionstyleTrue {
    position: absolute;
    top: 82%;
    right: 89.1%;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }
  .addbtnTrue {
    position: absolute;
    top: 82%;
    right: 91%;

    width: 55px !important;
    height: 25px !important;
    display: flex;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;
    color: #ffffff;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: max-content;
  }

  .addingCustBtnStyles {
    color: #fff !important;
    padding-top: 2.6px !important;
    padding-left: 2px !important;
    font-weight: 600 !important;
  }
  .productList {
    /*  max-height: 23rem !important; */
    overflow-y: scroll !important;
  }
  .imagePo {
    padding-right: 17.5px !important;
    height: 68px !important;
    margin-left: -11px !important;
  }
  .divisionstyle {
    position: absolute;
    top: 95%;
    right: -14%;
    /* left: 83%; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }

  .bootomButton {
    padding-top: 0px !important;
    bottom: auto !important;
  }
  .po {
    background-color: #f3f7f8;
    height: 100%;
  }
}

@media (min-width: 411px) and (max-width: 413px) {
  .divisionstyleTrue {
    position: absolute;
    top: 82%;
    right: 81.1%;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }
  .addbtnTrue {
    position: absolute;
    top: 82%;
    right: 82%;

    width: 55px !important;
    height: 25px !important;
    display: flex;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;
    color: #ffffff;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: max-content;
  }

  .addingCustBtnStyles {
    color: #fff !important;
    padding-top: 2.6px !important;
    padding-left: 2px !important;
    font-weight: 600 !important;
  }
  .imagePo {
    margin-left: -3px !important;
    padding-right: 16px !important;
  }
  .productList {
    /* max-height: 26.7rem !important; */
    overflow-y: scroll !important;
  }
  .bootomButton {
    padding-top: 0px !important;
  }
  .po {
    background-color: #f3f7f8;
    height: 100%;
  }
  .divisionstyle {
    position: absolute;
    top: 95%;
    right: -13.3%;
    /* left: 83%; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }
}

@media (min-width: 414px) and (max-width: 767px) {
  .divisionstyleTrue {
    position: absolute;
    top: 82%;
    right: 78.1%;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }
  .addbtnTrue {
    position: absolute;
    top: 82%;
    right: 81.1%;

    width: 55px !important;
    height: 25px !important;
    display: flex;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;
    color: #ffffff;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: max-content;
  }

  .addingCustBtnStyles {
    color: #fff !important;
    padding-top: 2.6px !important;
    padding-left: 2px !important;
    font-weight: 600 !important;
  }
  .imagePo {
    margin-left: -2.5px !important;
    padding-right: 16px !important;
    height: 68px !important;
  }
  .productList {
    /*  max-height: 27.2rem !important; */
    overflow-y: scroll !important;
  }
  .bootomButton {
    padding-top: 0px !important;
  }
  .po {
    background-color: #f3f7f8;
    height: 100%;
  }
  .divisionstyle {
    position: absolute;
    top: 95%;
    right: -14%;
    /* left: 83%; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .divisionstyleTrue {
    position: absolute;
    top: 82%;
    right: 40.1%;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }
  .addbtnTrue {
    position: absolute;
    top: 82%;
    right: 41.5%;

    width: 55px !important;
    height: 25px !important;
    display: flex;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;
    color: #ffffff;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: max-content;
  }

  .addingCustBtnStyles {
    font-size: 17px !important;
    color: #fff !important;
    padding-top: 0px !important;
    padding-left: 1px !important;
    font-weight: 600 !important;
  }
  .imagePo {
    margin-left: 86px !important;
    padding-right: 15.5px !important;
    height: 68px !important;
  }
  .productList {
    /*  max-height: 26rem !important; */
    overflow-y: scroll !important;
  }
  .bootomButton {
    padding-top: 0px !important;
  }
  /* .po {
    background-color: #f3f7f8;
    height: 100%;
  } */
  .divisionstyle {
    position: absolute;
    top: 95%;
    right: -7%;
    /* left: 83%; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .divisionstyleTrue {
    position: absolute;
    top: 82%;
    right: 30.1%;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }
  .addbtnTrue {
    position: absolute;
    top: 82%;
    right: 31%;

    width: 55px !important;
    height: 25px !important;
    display: flex;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;
    color: #ffffff;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: max-content;
  }

  .addingCustBtnStyles {
    font-size: 17px !important;
    color: #fff !important;
    padding-top: 2px !important;
    padding-left: 2px !important;
    font-weight: 600 !important;
  }
  .imagePo {
    margin-left: 149px !important;
    padding-right: 15.5px !important;
    height: 68px !important;
  }
  .productList {
    /*  max-height: 25.7rem !important; */
    overflow-y: scroll !important;
  }
  .divisionstyle {
    position: absolute;
    top: 95%;
    right: -5%;
    /* left: 83%; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }
}

@media only screen and (min-width: 1301px) {
  .divisionstyleTrue {
    position: absolute;
    top: 82%;
    right: 24.6%;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }
  .addbtnTrue {
    position: absolute;
    top: 82%;
    right: 25%;

    width: 55px !important;
    height: 25px !important;
    display: flex;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;
    color: #ffffff;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: max-content;
  }

  .addingCustBtnStyles {
    font-size: 17px !important;
    color: #fff !important;
    padding-top: 1.8px !important;
    padding-left: 1px !important;
    font-weight: 600 !important;
  }
  .imagePo {
    margin-left: 212px !important;
    padding-right: 16px !important;
    height: 68px !important;
  }
  .productList {
    /*  max-height: 22rem !important; */
    overflow-y: scroll !important;
  }

  .divisionstyle {
    position: absolute;
    top: 95%;
    right: -4.5%;
    /* left: 83%; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #f35d5d !important;

    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 60px;
    height: 23px;
  }
}
.subCategory-selected {
  background: #fff;
  margin: 5px 2px;
  border-radius: 0px;
  color: #ff3008 !important;
  text-align: center;
  padding: 8.5px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  width: 115%;
  height: 130px;
  border-bottom: 2px solid #ff3008 !important;
}

.subChildCategory-selected {
  background: #fff;
  margin: 5px 2px;
  border-radius: 0px;
  color: #ff3008 !important;
  text-align: center;
  padding: 0px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  width: 69px;
  height: 109px;
  border-bottom: 2px solid #ff3008 !important;
}

/* .subCategory :hover {
  border: 1px solid #ff3008 !important;
}
.subCategory-selected :active {
  border: 1px solid #ff3008 !important;
} */
.subCategory {
  background: #fff;
  margin: 5px 2px;
  border-radius: 3px;
  width: 115%;
  height: 130px;
  text-align: center;
  padding: 8.5px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: 1px solid #fff;
}

.subChildCategory {
  background: #fff;
  margin: 5px 2px;
  border-radius: 3px;
  width: 69px;
  height: 109px;
  text-align: center;
  padding: 7px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: 1px solid #fff;
}

.scrolling-wrapper {
  overflow-x: scroll;

  display: flex !important;
  white-space: nowrap !important;
}

/* .card {
  width: 300px;
  height: 110px;
} */
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 4px;
  padding-left: 4px;
}
.row {
  display: flex;
  flex-wrap: nowrap;
  margin-right: -17px;
  margin-left: -15px;
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: white !important;
  top: 57%;
  right: 0;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}
.rowSub {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 8px;
  margin-left: 26px;
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: white !important;
  top: 71%;

  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}
.rowSubCategory {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 8px;
  margin-left: 34px;
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: white !important;
  top: 71%;

  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

/* new css */
.addbtn {
  position: relative;
  width: 55px !important;
  height: 25px !important;
  background-color: #008000 !important;
  color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: max-content;
  padding-top: 1px !important;
  padding-left: 11px !important;
}
.addingBtnStyles {
  font-size: 10px !important;
  color: #fff !important;
  padding-top: 2.8px !important;
  padding-left: 2px !important;
  font-weight: 600 !important;
}
.favouriteButton {
  position: absolute;
  top: 97px;
  right: 42px;
  background-color: #fff;
  border: 0px;
  transform: translate(79%, -301%);
}
.favouriteButton1 {
  position: absolute;
  top: 97px;
  left: 5px;
  background-color: #fff;
  border: 0px;
  transform: translate(79%, -301%);
}
.favouriteButton2 {
  position: absolute;
  top: 97px;
  right: 132px;
  background-color: #fff;
  border: 0px;
  transform: translate(79%, -301%);
}
.favouriteButton3 {
  position: absolute;
  top: 97px;
  right: 85px;
  background-color: #fff;
  border: 0px;
  transform: translate(79%, -301%);
}
.favouriteButton4 {
  position: absolute;
  top: 97px;
  right: 42px;
  background-color: #fff;
  border: 0px;
  transform: translate(79%, -301%);
}
