.homeTitle {
  font-size: 20px;
  color: #292929;
  text-align: center;
  font-weight: 550;
  letter-spacing: 40;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.text-block {
  background-color: #fffcfb;
  color: white;
  padding-top: 12px;
}
.container {
  padding: 10px;
}
.ant-form-item-label > label {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  color: #fffcfb;
  font-size: 14px;
}
