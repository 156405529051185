.container {
  position: relative;
}

.container .content {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 10); /* Fallback color */
  background: rgba(0, 0, 10, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1;
  width: 100%;
  padding: 30px;
}
.input.largerCheckbox {
  width: 30px;
  height: 30px;
}
.ant-radio-checked .ant-radio-inner:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
